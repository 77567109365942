<template>
    <div class="border border-slate-25 dark:border-slate-800/60 bg-white dark:bg-slate-900 h-full p-6 w-full max-w-full md:w-3/4 md:max-w-[75%] flex-shrink-0 flex-grow-0">
      <page-header
        :header-title="$t('INBOX_MGMT.ADD.WHATAPPWEB.NAME')"
        :header-content="$t('INBOX_MGMT.ADD.WHATAPPWEB.DESC')"
      />
      <form class="row" @submit.prevent="createChannel()">
        <div v-show="!showQR" class="medium-8 columns">
          <label :class="{ error: $v.channelName.$error }">
            {{ $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.LABEL') }}
            <input
              v-model.trim="channelName"
              type="text"
              :placeholder="
                $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.PLACEHOLDER')
              "
              @blur="$v.channelName.$touch"
            />
            <span v-if="$v.channelName.$error" class="message">{{
              $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.ERROR')
            }}</span>
          </label>
        </div>
  
        <div v-show="!showQR" class="medium-8 columns">
          <label :class="{ error: $v.whatsappwebNumber.$error }">
            {{ $t('INBOX_MGMT.ADD.WHATAPPWEB.NUMBER.LABEL') }}
            <input
              v-model.trim="whatsappwebNumber"
              type="text"
              :placeholder="$t('INBOX_MGMT.ADD.WHATAPPWEB.NUMBER.PLACEHOLDER')
              "
              @blur="$v.whatsappwebNumber.$touch"
            />
            <span v-if="$v.whatsappwebNumber.$error" class="message">{{
              $t('INBOX_MGMT.ADD.WHATAPPWEB.NUMBER.ERROR')
            }}</span>
          </label>
        </div>
        
        <div v-show="showQR" class="medium-12 columns">
          <QRSteps
            v-if="showQR"
            @retryQr="processQRCode"
            :qrLink="whatsappwebQR"
          />
        </div>

        <div class="medium-12 columns" v-show="!showQR">
            <woot-submit-button
              :loading="uiFlags.isCreating"
              :button-text="$t('INBOX_MGMT.ADD.WHATAPPWEB.APINAME')"
            />
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  import alertMixin from 'shared/mixins/alertMixin';
  import { required,helpers } from 'vuelidate/lib/validators';
  import router from '../../../../index';
  import PageHeader from '../../SettingsSubPageHeader';
  import QRSteps from './../components/QRSteps';
  import SocketIO from 'socket.io-client';
  import axios, * as others from 'axios';
  import refresh_wa from 'dashboard/assets/images/channels/refresh_wa.png'
  import loading from 'dashboard/assets/images/channels/loader.gif'
  const customPhone = helpers.regex('customPhone', /(^(\d){10}$)|(^[+](\d){5,25}$)/)
  const shouldBeWebhookUrl = (value = '') =>
    value ? value.startsWith('http') : true;
  export default {
    components: {
      PageHeader,
      QRSteps,
    },
    mixins: [alertMixin],
    data() {
      return {
        channelName: '',
        images:{
          "refresh" : refresh_wa,
          "loading" : loading
        },
        webhookUrl: process.env.WHATSBAIL_WEB_API +'callpicker/webhook',
        whatsappwebNumber: '',
        formattedPhone: '',
        whatsappwebQR: loading,
        chatwootToken: '',
        showQR: false,
        canReloadQR: true,
        socket: null,
        otherClients : process.env.WHATS_APP_WEB_API_OTHER_CLIENTS
      };
    },
    mounted() {
      
    },  
    created() {
      window.addEventListener("beforeunload", this.unclosed);
    },  
    beforeDestroy() {
      console.log('beforeDestroy');
      this.unclosed(); 
    },
    computed: {
      ...mapGetters({
        uiFlags: 'inboxes/getUIFlags',
        accountId: 'getCurrentAccountId',
      }),
    },
    validations: {
      channelName: { required },
      whatsappwebQR: { required },
      webhookUrl: { shouldBeWebhookUrl },
      whatsappwebNumber: { 
        required,
        customPhone
      },
    },
    methods: {
      async formatPhone(){
        this.formattedPhone = this.whatsappwebNumber.replace('+', '');
        if(this.whatsappwebNumber.match(/(^(\d){10}$)/)){
          this.formattedPhone = "521"+ this.whatsappwebNumber;
        }
      },
      async createChannel() {
        console.log("createChannel");
        
        var self = this;
        this.$v.$touch();
        if (this.$v.$invalid) {
          return;
        }
        if(!this.showQR){
          self.showQR = true;
          console.log("Prepare socket");
          var socket_url = process.env.WHATSBAIL_WEB_WSS;
          if(JSON.parse(self.otherClients).includes(self.accountId+"")){
            socket_url = process.env.WHATSBAIL_WEB_WSS;
          }
          self.socket = await SocketIO(socket_url, {
            transports: ['websocket']
          });
          self.formatPhone()
          const key = btoa(self.formattedPhone);
          self.socket.emit('register', self.formattedPhone);

          await self.socket.on(`status`, (data) => {
            if(data.key != key){
              console.log("Skip event "+data.key);
              return;
            }
            switch (data.status) {
              case 'loading':
                  self.printQR(data.qr);
                break;
              case 'logged':
                  self.loggedQR();
                break;
              case 'failure':
                  console.log("failure");
                  self.canReloadQR = true;
                  self.retryQR();
                break;
              default:
                console.log("Invalid status");
                break;
            }
          })
          setTimeout(function(){ 
            console.log("Send Request");
            self.processQRCode();
          }, 500); 
          
          return;
        }
        try {
          console.log("Create Channel Request");
          const apiChannel = await this.$store.dispatch('inboxes/createChannel', {
            name: this.channelName,
            settings: {
              is_disconnected: false,
              number: this.formattedPhone,
              type_api: "whatsbail"
            },
            channel: {
              type: 'api',
              sub_type: 'whatsbail',
              webhook_url: this.webhookUrl,
              whatsapp_key: btoa(this.formattedPhone),
              whatsappweb_number: this.formattedPhone,
            },
          });
          router.replace({
            name: 'settings_inboxes_add_agents',
            params: {
              page: 'new',
              inbox_id: apiChannel.id,
            },
          });
        } catch (error) {
          this.canReloadQR = true;
          this.retryQR();
          this.showAlert(this.$t('INBOX_MGMT.ADD.API_CHANNEL.API.ERROR_MESSAGE'));
        }
      },
      processQRCode(){
        console.log("processQRCode");
        var self = this;
        if(!self.canReloadQR){
          return;
        }
        
        self.canReloadQR = false;
        self.whatsappwebQR = this.images['loading'];
        self.formatPhone()

        var url = process.env.WHATSBAIL_WEB_API + 'callpicker/register';
        if (JSON.parse(self.otherClients).includes(self.accountId + "")) {
          url = process.env.WHATSBAIL_WEB_API + 'callpicker/register';
        }
        axios.post(url, {
          phone: self.formattedPhone
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
          self.canReloadQR = true;
          self.retryQR();
        });
      },
      retryQR(){
        console.log("retryQR " + this.images['refresh']);
        this.whatsappwebQR = this.images['refresh'];
      },
      printQR(qr){
        var self = this;
        this.whatsappwebQR = this.images['loading'];
        setTimeout(function(){ 
          self.whatsappwebQR = qr;
        }, 500); 
      },
      loggedQR(){
        this.createChannel()
      },
      unclosed(){
        console.log("unclosed");
        if(this.socket){
          this.socket.emit('unclosed');
        }
      }
    },
  };
  </script>
  
